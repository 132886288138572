<template>

    <!-- 포인트 컬러 적용을 위해 p-level1~3 클래스 필요-->
    <div class="wrap p-level-book" :class="'p-level' + level">
        <!-- header -->
        <header class="header-step">
            <div class="head-left">
                <button type="button" class="btn-back" @click="$router.go(-1)"><span class="blind">이전 페이지</span>
                </button>
            </div>
            <div class="head-center">
                <div class="head-tit" v-if="eduMain != null">
                    <span class="page-tit">{{ eduMain.shortTitle }}</span>
                    <span class="book-tit">{{ eduMain.eduTitle }}</span>
                </div>
            </div>
            <div class="head-right"></div>
        </header>
        <!-- container -->
        <div class="container">
            <div class="content">
                <!-- 컨텐츠 갯수에 따라 split-n 클래스 필요-->
                <ul class="cont-list" :class="contentClass">

                    <!-- 시청 완료된 컨텐츠에는 done 클래스 -->
                    <li :class="{ 'done' : (answerList[i] != null && answerList[i].isComplete)}"
                        v-for="(item, i) in contentList">

                            <!-- 영상 -->
                            <template v-if="item.contentType === 'mov'">
	                            <a href="javascript:;" class="item-link" @click="doAnswer(eduMain.emIdx, i);">
                                <div class="thumb" :style="'background-image: url(' + item.qstMovVO.movThumb + ')'"></div>
                                <strong class="item-tit">{{ item.qstMovVO.movTitle }}</strong>
	                            </a>
                            </template>
                            <!-- 테스트 -->
                            <template v-if="item.contentType === 'tst'">
	                            <a href="javascript:;" class="item-link quiz-link" @click="doAnswer(eduMain.emIdx, i);">
                                <a href="javascript:;" class="report-link" v-if="(answerList[i] != null && answerList[i].isComplete)">Report</a>
                                <div class="thumb" style="background-image: url(/assets/images/learning/img_book_quiz.png)"></div>
	                            <strong class="item-tit">Test</strong>
	                            </a>
                            </template>

                    </li>

                    <!-- 테스트 -->
                    <!--					<li>
                                            <a href="#" class="report-link">Report</a>
                                            <a href="#" class="item-link quiz-link">
                                                <div class="thumb" style="background-image: url(/assets/images/learning/img_book_quiz.png)"></div>
                                                <strong class="item-tit">Quiz</strong>
                                            </a>
                                        </li>-->

                </ul>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "contentList",
    data() {

        var swiper = () => {
            new this.Swiper(".level-list", {
                slidesPerView: 3.2,
                spaceBetween: 20,
                pagination: {
                    el: ".swiper-pagination",
                },
            })
        };

        return {

            swiper,

            level: this.$route.query.level,
            emIdx: this.$route.query.emIdx,
            eduMain: null,
            contentList: [],
            answerList: [],

			contentClass: "",
        }
    },
    created() {

        this.$eventBus.$emit("startProgress")
        this.getEduMain(this.emIdx, this.getLoginId());
    },
    mounted() {
    },
    methods: {

        getStudent: function (emIdx, userId) {

            var self = this;
            self.completeContentCnt = 0;

            this.axiosCaller.get(this, this.APIs.EDU_STUDENT + "/", {
                emIdx: emIdx,
                userId: userId,
            }, function (res) {

                const result = res.data
                //console.log(result)

                if (result.status === "ok") {
                    self.eduStudent = result.eduStudentVO;
                    self.answerList = result.answerList;

                    /**
                     * 사용자의 이수 컨텐츠 합
                     */
                    var index = 0;
                    self.answerList.forEach(ans => {

                        if (!ans.isComplete) {
                            self.isEnableComplete = false
                        }

                        if (ans.contentType === 'tst') {
                            self.completeContentCnt += ans.tstSolveCount;
                        } else if (ans.contentType === 'mov') {

                            if (ans.isComplete || Math.floor(ans.ansMovVO.currentTimes / 60) >= self.eduMain.contentList[index].qstMovVO.playMinutes) {
                                /**
                                 * 영상이수완료 or 플레이시간이 필수플레이 시간보다 많을 경우
                                 */
                                self.completeContentCnt += self.eduMain.contentList[index].qstMovVO.playMinutes
                            } else {
                                /**
                                 * 플레이 시간을 분단위로 추가
                                 * @type {number}
                                 */
                                self.completeContentCnt += Math.floor(ans.ansMovVO.currentTimes / 60);
                            }
                        } else if (ans.contentType === 'file') {
                            if (ans.isComplete) {
                                self.completeContentCnt += self.eduMain.contentList[index].qstFileVO.fileList.length;
                            } else {
                                self.completeContentCnt += ans.fileDownloadCount;
                            }
                        }
                        index++;
                    })

                    //console.log("self.completeContentCnt ::::: " + self.completeContentCnt);
                } else {
                    console.log(result.msg)
                }
                self.$eventBus.$emit("doneProgress")
            })

        },


        getEduMain: function (_emIdx, _userId) {

            var self = this

            self.$eventBus.$emit("startProgress")
            this.axiosCaller.get(this, this.APIs.EDU_MAIN + "/getEduMain", {
                emIdx: _emIdx,
                userId: _userId,
            }, async function (res) {
	            self.$eventBus.$emit("doneProgress")

	            const result = res.data
	            console.log(result)
	            if (result.status === "ok") {

		            self.eduMain = result.eduMain
		            self.contentList = self.eduMain.contentList
					self.contentClass = (self.contentList.length <= 7) ? "split-" + self.contentList.length : "split-more";

		            // 현재 신청자 + 완료자 합계산
		            self.finishCount = self.eduMain.requestCount + self.eduMain.completeCount;

		            self.eduMain.playMinutes = 0;
		            self.allContentCnt = 0;
		            self.eduMain.contentList.forEach(cv => {

			            // 교육 컨텐츠의 개수 합 + 기타 표출용 텍스트 정의
			            if (cv.contentType === 'tst') {
				            self.eduMain.isTest = true;
				            self.eduMain.isTestTxt = "있음";
				            self.allContentCnt += cv.qstTstList.length;
			            } else if (cv.contentType === 'mov') {

				            if (cv.qstMovVO == null) {
					            self.swalUtils.fire("교육 구성이 진행중입니다.", "", "info", null, null)
						            .then(t => {
							            self.$router.go(-1);
						            })
					            return
				            }

				            self.eduMain.playMinutes += cv.qstMovVO.playMinutes;
				            self.allContentCnt += cv.qstMovVO.playMinutes;
			            } else if (cv.contentType === 'file') {

				            if (cv.qstFileVO == null) {
					            history.back();
					            return
				            }

				            self.allContentCnt += cv.qstFileVO.fileList.length;
			            }

			            self.eduMain.introduce = self.$options.filters.nl2br(self.eduMain.introduce);
			            self.eduMain.goal = self.$options.filters.nl2br(self.eduMain.goal);

			            // 교육항목 저장
			            self.eduContentList = self.eduMain.contentList

			            // self.fadeIn('.__pop.createResult')

			            // 현재 교육 관심, 좋아요 조회
			            //self.getWishLike( self.emIdx, self.userId );

		            })

		            await self.doRequest();
		            self.getStudent(self.emIdx, self.userId);
	            }

	            //console.log("allContentCnt ::::: " + self.allContentCnt)
            })

        },


        async doRequest() {

            if (!await this.requireLogin(this)) {
                return
            }

            const self = this
	        return new Promise(resolve => {

		        this.axiosCaller.put(this, this.APIs.EDU_STUDENT + "/request", {
			        emIdx: this.eduMain.emIdx,
			        userId: this.getLoginId(),
		        }, function (res) {
			        const result = res.data
			        if ( result.status !== "ok" ) {
				        self.swalUtils.gritter("관리자에게 문의해주세요.", result.msg, "error")
					        .then(() => {
						        self.$router.back()
					        })
				        return;
			        } else {
				        resolve( result )
			        }
		        })
	        })

        },


        /**
         * 학습 시작하기
         * @param emIdx
         */
        doAnswer: function (emIdx, index) {

            const url = this.APIs.BASE_URL + "/proceed/" + emIdx + "/" + btoa(this.getLoginId()) + "/?playStep=" + index + "&level=" + this.level;

            const temp = {
                url: url,
                target: "proceed",
                isCloseBTN: "Y",
            }
            this.appOpen(temp);
        },

    },
}
</script>

<style scoped>

</style>